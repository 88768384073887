import { ActivityLogActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: false,
    SELF_ACTIVITY_LOG: null,
    ACTIVITY_LOGS: null,
    SINGLE_ACTIVITY_LOG: null
}

export const activityLogReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case ActivityLogActionTypes.GET_SELF_ACTIVITY_LOG: {
            return { ...state, SELF_ACTIVITY_LOG: payload, isLoading: false }
        }

        case ActivityLogActionTypes.GET_ALL_ACTIVITY_LOGS: {
            return { ...state, ACTIVITY_LOGS: payload, isLoading: false }
        }

        case ActivityLogActionTypes.GET_SINGLE_ACTIVITY_LOG: {
            return { ...state, SINGLE_ACTIVITY_LOG: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}