import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux'

// Chakra Provider
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { PersistGate } from 'redux-persist/integration/react';
import customTheme from './utils/theme';

ReactDOM.render(

  <Provider store={store}>
    <ChakraProvider>
      <React.StrictMode>
        <BrowserRouter>
          <PersistGate loading={null} persistor={persistor}>
            <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
            <App />
          </PersistGate>
        </BrowserRouter>
      </React.StrictMode>
    </ChakraProvider>
  </Provider>,
  document.getElementById('root')

);
