/* eslint-disable no-unused-vars */
import { composeWithDevTools } from '@redux-devtools/extension'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import thunkMiddleware from 'redux-thunk'

// import thunk from 'redux-thunk';
import reducers from './reducers';

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)


export const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export const persistor = persistStore(store)
