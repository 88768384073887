import { AppGroupActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: false,
    APP_GROUPS: null,
    SINGLE_APPGROUP: null
}

export const appGroupReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case AppGroupActionTypes.GET_ALL_APP_GROUPS: {
            return { ...state, APP_GROUPS: payload, isLoading: false }
        }

        case AppGroupActionTypes.GET_SINGLE_APP_GROUP: {
            return { ...state, SINGLE_TPI: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}