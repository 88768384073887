import { AppBundleActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: true,
    APP_BUNDLES: null,
    SINGLE_APP_BUNDLE: null
}

export const appBundleReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case AppBundleActionTypes.GET_ALL_APPBUNDLES: {
            return { ...state, APP_BUNDLES: payload, isLoading: false }
        }

        case AppBundleActionTypes.GET_SINGLE_APPBUNDLE: {
            return { ...state, SINGLE_APP_BUNDLE: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}