import { AppActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: true,
    APPS: null,
    SINGLE_APP: null
}

export const appReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case AppActionTypes.GET_ALL_APPS: {
            return { ...state, APPS: payload, isLoading: false }
        }

        case AppActionTypes.GET_SINGLE_APP: {
            return { ...state, SINGLE_APP: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}