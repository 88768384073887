import { combineReducers } from 'redux';
import { adminReducer } from './adminReducer';
import { appBundleReducer } from './appBundlesReducer';
import { appReducer } from './appReducer';
import { testerReducer } from './testerReducer'

import { dashboardReducer } from './dashboardReducer';
import { priviledgesReducer } from './priviledgesReducer';
import { tpiReducer } from './tpiReducer';
import { appGroupReducer } from './appGroupReducer';
import { activityLogReducer } from './activityLogReducer';
const reducers = combineReducers({
    dashboard: dashboardReducer,
    activityLogs: activityLogReducer,
    priviledges: priviledgesReducer,
    admin: adminReducer,
    apps: appReducer,
    app_groups: appGroupReducer,
    app_bundles: appBundleReducer,
    tpis: tpiReducer,
    testers: testerReducer
});

export default reducers;