import { AdminActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: false,
    SELF_ADMIN: null,
    ADMINS: null,
    SINGLE_ADMIN: null
}

export const adminReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case AdminActionTypes.GET_SELF_ADMIN: {
            return { ...state, SELF_ADMIN: payload, isLoading: false }
        }

        case AdminActionTypes.GET_ALL_ADMINS: {
            return { ...state, ADMINS: payload, isLoading: false }
        }

        case AdminActionTypes.GET_SINGLE_ADMIN: {
            return { ...state, SINGLE_ADMIN: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}