import { LoadActionTypes, PriviledgeActionTypes } from "../constants"

const initialState = {
    isLoading: true,
    PRIVILEDGES: null
}

export const priviledgesReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case PriviledgeActionTypes.GET_PRIVILEDGES: {
            return { ...state, PRIVILEDGES: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}