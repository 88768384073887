import { AdminActionTypes, LoadActionTypes, TPIActionTypes } from "../constants"

const initialState = {
    isLoading: false,
    TPIS: null,
    ASSIGNED_APPGROUPS: null,
    SINGLE_TPI: null
}

export const tpiReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case TPIActionTypes.GET_ALL_TPIS: {
            return { ...state, TPIS: payload, isLoading: false }
        }

        case TPIActionTypes.GET_SINGLE_TPI: {
            return { ...state, SINGLE_TPI: payload, isLoading: false }
        }

        case TPIActionTypes.GET_ASSIGNED_APPGROUPS: {
            return { ...state, ASSIGNED_APPGROUPS: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}