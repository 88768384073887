export const LoadActionTypes = {
    LOADING_CONTENT: 'LOADING_CONTENT',
    LOAD_COMPLETED: 'LOAD_COMPLETED',
    SUCCESS_REQUEST: 'SUCCESS_REQUEST',
    EMPTY_LIST: 'EMPTY_LIST'
};

export const DashboardActionTypes = {
    GET_DASHBOARD: 'GET_DASHBOARD'
}

export const ActivityLogActionTypes = {
    GET_SELF_ACTIVITY_LOG: 'GET_SELF_ACTIVITY_LOG',
    GET_ALL_ACTIVITY_LOGS: 'GET_ALL_ACTIVITY_LOGS',
    GET_SINGLE_ACTIVITY_LOG: 'GET_SINGLE_ACTIVITY_LOG'
}

export const AdminActionTypes = {
    GET_SELF_ADMIN: 'GET_SELF_ADMIN',
    GET_ALL_ADMINS: 'GET_ALL_ADMINS',
    GET_SINGLE_ADMIN: 'GET_SINGLE_ADMIN'
}

export const PriviledgeActionTypes = {
    GET_PRIVILEDGES: 'GET_PRIVILEDGES'
}

export const AppActionTypes = {
    GET_ALL_APPS: 'GET_ALL_APPS',
    GET_SINGLE_APP: 'GET_SINGLE_APP'
}

export const AppGroupActionTypes = {
    GET_ALL_APP_GROUPS: 'GET_ALL_APP_GROUPS',
    GET_SINGLE_APP_GROUP: 'GET_SINGLE_APP_GROUP'
}

export const AppGroupTypes = {
    GET_ALL_APPGROUPS: 'GET_ALL_APPGROUPS',
    GET_SINGLE_APPGROUP: 'GET_SINGLE_APPGROUP',
}

export const AppBundleActionTypes = {
    GET_ALL_APPBUNDLES: 'GET_ALL_APPBUNDLES',
    GET_SINGLE_APPBUNDLE: 'GET_SINGLE_APPBUNDLE'
}

export const TesterActionTypes = {
    GET_ALL_TESTERS: 'GET_ALL_TESTERS',
    GET_SINGLE_TESTER: 'GET_SINGLE_TESTER'
}

export const TPIActionTypes = {
    GET_ALL_TPIS: 'GET_ALL_TPIS',
    GET_SINGLE_TPI: 'GET_SINGLE_TPI',
    GET_ASSIGNED_APPGROUPS: 'GET_ASSIGNED_APPGROUPS'
}