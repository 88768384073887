import { DashboardActionTypes, LoadActionTypes } from "../constants";

const initialState = {
    isLoading: false
}

export const dashboardReducer = (state = initialState, { variant, payload }) => {
    switch (variant) {
        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true };
        }
        case DashboardActionTypes.GET_DASHBOARD: {
            return { ...state, DASHBOARD: payload, isLoading: false };
        }
        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, issue: payload, isLoading: false };
        }
        default:
            return state;
    }
};