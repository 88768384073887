import { TesterActionTypes, LoadActionTypes } from "../constants"

const initialState = {
    isLoading: true,
    TESTERS: null,
    SINGLE_TESTER: null
}

export const testerReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LoadActionTypes.LOADING_CONTENT: {
            return { ...state, isLoading: true }
        }

        case TesterActionTypes.GET_ALL_TESTERS: {
            return { ...state, TESTERS: !payload ? null : payload, isLoading: false }
        }

        case TesterActionTypes.GET_SINGLE_TESTER: {
            return { ...state, SINGLE_TESTER: payload, isLoading: false }
        }

        case LoadActionTypes.LOAD_COMPLETED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}