import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import { CircularProgress, Flex } from "@chakra-ui/react";

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <Flex
          height={"100vh"}
          bg={"gray.900"}
          align={"center"}
          justify={"center"}
        >
          <CircularProgress color="white" height={100} width={100} />
        </Flex>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// layouts
const Dashboard = Loadable(lazy(() => import("./components/layout/Dashboard")));
const BlankLayout = Loadable(
  lazy(() => import("./components/layout/BlankLayout"))
);
const AdminsLayout = Loadable(
  lazy(() => import("./components/layout/AdminsLayout"))
);
const AdminSingleLayout = Loadable(
  lazy(() => import("./components/layout/AdminSingleLayout"))
);
const AppsLayout = Loadable(
  lazy(() => import("./components/layout/AppsLayout"))
);
const AppSingleLayout = Loadable(
  lazy(() => import("./components/layout/AppSingleLayout"))
);
const AppGroupsLayout = Loadable(
  lazy(() => import("./components/layout/AppGroupsLayout"))
);
const AppGroupSingleLayout = Loadable(
  lazy(() => import("./components/layout/AppGroupSingleLayout"))
);

// pages
const Login = Loadable(lazy(() => import("./pages/Login")));
const ForgotPassword = Loadable(lazy(() => import("./pages/ForgotPassword")));
const AccountSettings = Loadable(lazy(() => import("./pages/AccountSettings")));
const Testers = Loadable(lazy(() => import("./pages/testers/Testers")));
const UserApps = Loadable(lazy(() => import("./pages/apps/UserApps")));
const Admins = Loadable(lazy(() => import("./pages/admin/Admins")));
const AppGroups = Loadable(lazy(() => import("./pages/app-groups/AppGroups")));
const ActivityLogs = Loadable(
  lazy(() => import("./pages/activity-logs/ActivityLogs"))
);
const TPI = Loadable(lazy(() => import("./pages/tpi/TPI")));

// Application Routes
const routes = [
  // [0] - Views accessible for an authenticated user
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/apps",
        element: <AppsLayout />,
        children: [
          { path: "/apps", element: <UserApps /> },
          { path: "/apps/*", element: <AppSingleLayout /> },
        ],
      },
      {
        path: "/staff",
        element: <AdminsLayout />,
        children: [
          { path: "/staff", element: <Admins /> },
          { path: "/staff/*", element: <AdminSingleLayout /> },
        ],
      },
      // {
      //   path: "/app-groups",
      //   element: <AppGroupsLayout />,
      //   children: [
      //     { path: "/app-groups", element: <AppGroups /> },
      //     { path: "/app-groups/*", element: <AppGroupSingleLayout /> },
      //   ],
      // },
      // { path: "/tpis", element: <TPI /> },
      { path: "/activity-logs", element: <ActivityLogs /> },
      // { path: "/testers", element: <Testers /> },
      { path: "/account-settings", element: <AccountSettings /> },
      { path: "*", element: <Navigate to="/apps" /> },
    ],
  },

  // [1] - Views accessible for an unauthenticated user
  {
    element: <BlankLayout />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/forgot-password", element: <ForgotPassword /> },

      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
];

export default function Router() {
  const token = localStorage.getItem("token");

  // Displays views meant for an authenticated user (Dashboard)
  const AUTHENTICATED_ROUTES = useRoutes([routes[0]]);

  // Displays views meant for an unauthenticated user (Authentication flow/screens)
  const UNAUTHENTICATED_ROUTES = useRoutes([routes[1]]);

  const element = token ? AUTHENTICATED_ROUTES : UNAUTHENTICATED_ROUTES;

  return element;
}
